<template>
  <div class="homeContent">
    <div class="home-header">
      <div class="home-head">
        <h3>热烈庆祝深圳写手智能科技公司</h3>
        <h3>双子星软件“成语”“反抄袭”</h3>
        <div class="home-segistr">2022年5月20日正式上线运行</div>
        <div class="segistr-now" @click="segistrNow">现在注册</div>
        <div class="segistr-discounts">现在注册获赠5000字符</div>
      </div>

      <!-- <img src="../assets/images/home/01.png" alt="" />
      <div class="home-button" @click="initiation">开始使<span>用</span></div> -->
    </div>
    <div class="home-banner-box">
      <div
        class="home-ban-content"
        @mouseover="showExperience = true"
        @mouseleave="showExperience = false"
      >
        <img src="../assets/images/home/04.png" alt="" />
        <h2>溯源</h2>
        <p>版权作品</p>
        <p>追根溯源</p>
        <p>原创作者</p>
        <div v-if="showExperience" class="experience1" @click="experience(1)">
          立即体验
        </div>
      </div>
      <div
        class="home-ban-content1"
        @mouseover="showExperience1 = true"
        @mouseleave="showExperience1 = false"
      >
        <img src="../assets/images/home/05.png" alt="" />
        <h2>原创存证</h2>
        <p>版权作品</p>
        <p>知识产权证据链</p>
        <p>保护原创</p>
        <div v-if="showExperience1" class="experience2" @click="experience(2)">
          立即体验
        </div>
      </div>
      <div
        class="home-ban-content2"
        @mouseover="showExperience2 = true"
        @mouseleave="showExperience2 = false"
      >
        <img src="../assets/images/home/06.png" alt="" />
        <h2>智能网盘</h2>
        <p>存储存证、文档</p>
        <p>文档OCR识别</p>
        <p>智能推荐</p>
        <div v-if="showExperience2" class="experience3" @click="experience(3)">
          立即体验
        </div>
      </div>
      <div
        class="home-ban-content3"
        @mouseover="showExperience3 = true"
        @mouseleave="showExperience3 = false"
      >
        <img src="../assets/images/home/07.png" alt="" />
        <h2>版权收益</h2>
        <p>作品授权</p>
        <p>自定义价格</p>
        <p>获得收益</p>
        <div v-if="showExperience3" class="experience4" @click="experience(4)">
          立即体验
        </div>
      </div>
      <div
        class="home-ban-content4"
        @mouseover="showExperience4 = true"
        @mouseleave="showExperience4 = false"
      >
        <img src="../assets/images/home/08.png" alt="" />
        <h2>版权服务</h2>
        <p>知识产权</p>
        <p>一站式服务</p>
        <p>执业律师</p>
        <div v-if="showExperience4" class="experience5" @click="experience(5)">
          立即体验
        </div>
      </div>
    </div>
    <div class="home-thied">
      <el-carousel :interval="5000" height="560px">
        <el-carousel-item v-for="(item, index) in third" :key="index">
          <div class="item1">
            <div class="item_text_box">
              <div class="title">
                {{ item.title }}
              </div>
              <div class="text">
                {{ item.text }}
              </div>
            </div>
            <div class="item_img_box">
              <img :class="item.class" :src="item.src" alt="" />
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="home-good">
      <h2>反抄袭</h2>
      <h3>原创版权收益与服务</h3>
      <div class="home-good-content">
        <div class="home-good-left">
          <div class="home-box">
            <div>
              <img src="../assets/images/home/09.png" alt="" />
            </div>
            <div>
              <h4>原创保护</h4>
              <p>
                致力于为原创文字作品、图片、视频、文件等提供电子数据指纹，适配国家授时中心时间戳，形成周密稳固的知识产权证据链，以更好保护原创作者。
              </p>
            </div>
          </div>
          <div class="home-box" style="margin-top: 71px">
            <div>
              <img src="../assets/images/home/11.png" alt="" />
            </div>
            <div>
              <h4>版权维护</h4>
              <p>
                提供智能网盘、版权收益、版权服务等一站式知识产权服务，由执业律师提供标准化的流程公开、价格透明的咨询与实务服务。
              </p>
            </div>
          </div>
        </div>
        <div class="home-good-right">
          <div class="home-box">
            <div>
              <img src="../assets/images/home/10.png" alt="" />
            </div>
            <div>
              <h4>版权收益</h4>
              <p>
                原创作者可以通过作品授权，自主设定授权价格，获得现金等收益。文字作品的最早原创者，在所有溯源中支付的字符数，涉及到篇、段、句的层面，都将直接获得重复字段的字符数。
              </p>
            </div>
          </div>

          <div class="home-box" style="margin-top: 71px">
            <div>
              <img src="../assets/images/home/12.png" alt="" />
            </div>
            <div>
              <h4>联系我们</h4>
              <p>
                联系电话：
                <span style="color: #4587ff; margin-right: 15px"
                  >0755-23345494</span
                >
                <span style="color: #4587ff">13828584514</span>（颜先生）<br />
                E - mail： &nbsp;
                <span style="color: #4587ff">writemall@126.com</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="banner-register">
      <img src="../assets/images/01/07.png" alt="" />
      <div class="register-new">
        <el-input v-model="value"></el-input>
      </div>
    </div> -->
    <!-- <div class="fooder-box1">
      <div class="header">实时数据</div>
      <div class="header-hd">Real time data</div>
      <div class="timemarker">
        <div class="box">
          <div class="nub">122098566<span>/篇</span></div>
          <div class="box2"></div>
          <div>反抄袭数据库</div>
        </div>
        <div class="box">
          <div class="nub">9090523<span>/篇</span></div>
          <div class="box3"></div>
          <div>今日新增</div>
        </div>
        <div class="box1">
          <div class="nub">102055<span>/人</span></div>
          <div class="box4"></div>
          <div>正在使用</div>
        </div>
      </div>
    </div> -->
    <div class="bannerC">
      <div class="fooder-top">
        <div class="fooder-left">
          <div class="fooder-hd">
            <!-- <img src="../assets/images/01/06.png" alt="" /> -->
            <img src="@/assets/images/introduce/04.png" alt="" />
          </div>
          <div class="fooder-bd">
            <div class="fooder-conter">
              <div @click="experience(1)">溯源</div>
              <div @click="experience(2)">原创存证</div>
              <div @click="experience(3)">智能网盘</div>
            </div>
            <div class="fooder-conter1">
              <div @click="experience(4)">版权收益</div>
              <div @click="experience(5)">版权服务</div>
              <div @click="experience(6)">关于我们</div>
            </div>
          </div>
        </div>
        <div class="fooder-right">
          <div>联系我们</div>
          <div class="right-box">0755-23345494</div>
          <div class="img_box">
            <div class="imgcode">
              <img
                class="img"
                src="@/assets/images/download-guide/02.png"
                alt=""
              />
              公众号
            </div>
            <div class="imgcode">
              <img src="@/assets/images/download-guide/01.png" alt="" />
              <!-- <img src="@/assets/images/introduce/06.png" alt="" /> -->
              企业微信
            </div>
          </div>
        </div>
      </div>
      <div class="fooder-bottom">
        深圳写手智能科技有限公司 Shenzhen writer Intelligent Technology Co., Ltd
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >粤ICP备20062435号</a
        >
      </div>
    </div>
    <Header ref="headerRegister" />
  </div>
</template>

<script>
import Header from "components/header/Header.vue";
import { getToken } from "../utils/auth.js";
export default {
  components: {
    Header,
  },
  data() {
    return {
      showExperience: false,
      showExperience1: false,
      showExperience2: false,
      showExperience3: false,
      showExperience4: false,
      value: "",
      fromPage: "",
      third: [
        {
          title: "溯源",
          text: "溯源是对文字作品、图片、视频、文件等版权作品的追根溯源，通过匹配电子数据指纹，进而确定最早的原创作者。反抄袭数据库，单一电子数据指纹，仅保留最早的原创作者。",
          src: require("assets/images/introduce/31.png"),
          class: "img",
        },

        {
          title: "原创存证",
          text: "原创存证是对文字作品、图片、视频、文件等版权作品，适配国家授时中心时间戳，形成周密稳固的知识产权证据链，以更好保护原创作者。",
          src: require("assets/images/introduce/32.png"),
          class: "img1",
        },

        {
          title: "智能网盘",
          text: "智能网盘主要是存储原创存证、云文档、收藏文档等常用存储功能。智能方面，主要是在用户同意授权情况下，可以对图片、PDF文档、CA",
          src: require("assets/images/introduce/28.png"),
          class: "img2",
        },

        {
          title: "版权收益",
          text: "版权收益是指原创作者可以通过作品授权，自主设定授权价格，获得现金、字符包等收益。文字作品的最早原创者，在所有溯源中支付的字符数，涉及到篇、段、句的层面，都将直接获得重复字段的字符数。",
          src: require("assets/images/introduce/29.png"),
          class: "img3",
        },

        {
          title: "版权服务",
          text: "版权服务主要是围绕知识产权提供版权实务、信息汇编、实名认证、收录确权、会员服务等一站式服务，由知名的知识产权领域执业律师提供标准化的流程公开、价格透明的咨询与实务服务，是原创作者、知识博主、杂志期刊与媒体机构、新媒体的重要助手。",
          src: require("assets/images/introduce/30.png"),
          class: "img4",
        },
      ],
    };
  },
  mounted() {
    /* 没有令牌不可以访问的页面 */
    // if (!getToken("auth_token")) {
    //   this.$refs["headerRegister"].login_flag = true;
    // }
  },

  methods: {
    //   开始使用
    initiation() {
      this.$router.push({
        name: "TraceTheSource",
      });
    },
    experience(val) {
      if (val == 1) {
        this.$router.push({
          name: "TraceTheSource",
        });
      } else if (val == 2) {
        this.$router.push({
          name: "Store",
        });
      } else if (val == 3) {
        this.$router.push({
          name: "disk",
        });
      } else if (val == 4) {
        this.$router.push({
          name: "copyrightIncome",
        });
      } else if (val == 5) {
        this.$router.push({
          name: "copyrightService",
        });
      } else {
        this.$router.push({
          name: "aboutus",
        });
      }
    },
    segistrNow() {
      this.$refs["headerRegister"].register_flag = true;
      // window.open("http://passport.writemall.com/");
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
a {
  color: #000;
}
.homeContent {
  width: 100%;
  position: absolute;
  left: 0;
  margin-top: -30px;
  .home-header {
    width: 100%;
    height: 450px;
    text-align: center;
    img {
      width: 100%;
      height: 100%;
    }
    .home-button {
      cursor: pointer;
      width: 226px;
      height: 70px;
      margin: 0 auto;
      transform: translateY(-52%);
      background: #4587ff;
      border-radius: 10px;
      line-height: 70px;
      font-size: 26px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      z-index: 999;
    }
    .home-head {
      width: 100%;
      height: 100%;
      background: url("../assets/images/01/01.png") no-repeat;
      background-size: 100% 100%;
      padding: 70px 0 64px 349px;
      box-sizing: border-box;
      text-align: left;
      h3 {
        // line-height: 43px;
        font-size: 41px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 21px;
      }
      .home-segistr {
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 44px;
        margin-top: 7px;
      }
      .segistr-now {
        cursor: pointer;
        width: 214px;
        height: 58px;
        background: url("../assets/images/01/02.png") no-repeat;
        background-size: 100% 100%;
        margin: 56px 0 20px;
        text-align: center;
        line-height: 58px;
        font-size: 26px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
        text-shadow: 0px 0px 28px #1067e8;
      }
      .segistr-discounts {
        font-size: 23px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #a4fffd;
        // line-height: 44px;
      }
    }
  }
  .home-banner-box {
    display: flex;
    justify-content: space-between;
    padding: 111px 42px 210px 191px;
    box-sizing: border-box;
    width: 100%;
    height: 740px;
    margin-top: 36px;
    .home-ban-content {
      width: 181px;
      height: 360px;
      background-image: url("../assets/images/home/02.png");
      background-size: 100% 100%;
      text-align: center;
      img {
        width: 83px;
        height: 80px;
        margin-top: 44px;
      }
      h2 {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #4588ff;
        margin: 53px 0 15px 0;
      }
      p {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #4588ff;
        line-height: 29px;
      }
      .experience1 {
        cursor: pointer;
        display: inline-block;
        width: 90px;
        height: 30px;
        background: #4587ff;
        border-radius: 15px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 30px;
        margin-top: 10px;
      }
    }
    .home-ban-content1 {
      margin-top: 59px;
      width: 181px;
      height: 360px;
      background-image: url("../assets/images/home/03.png");
      background-size: 100% 100%;
      text-align: center;
      img {
        width: 83px;
        height: 80px;
        margin-top: 44px;
      }
      h2 {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        margin: 53px 0 15px 0;
      }
      p {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 29px;
      }
      .experience2 {
        cursor: pointer;
        display: inline-block;
        width: 90px;
        height: 30px;
        background: #fff;
        border-radius: 15px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #4587ff;
        line-height: 30px;
        margin-top: 10px;
      }
    }
    .home-ban-content2 {
      width: 181px;
      height: 360px;
      background-image: url("../assets/images/home/02.png");
      background-size: 100% 100%;
      text-align: center;
      img {
        width: 83px;
        height: 80px;
        margin-top: 44px;
      }
      h2 {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #4588ff;
        margin: 53px 0 15px 0;
      }
      p {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #4588ff;
        line-height: 29px;
      }
      .experience3 {
        cursor: pointer;
        display: inline-block;
        width: 90px;
        height: 30px;
        background: #4587ff;
        border-radius: 15px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 30px;
        margin-top: 10px;
      }
    }
    .home-ban-content3 {
      margin-top: 59px;
      width: 181px;
      height: 360px;
      background-image: url("../assets/images/home/03.png");
      background-size: 100% 100%;
      text-align: center;
      img {
        width: 83px;
        height: 80px;
        margin-top: 44px;
      }
      h2 {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        margin: 53px 0 15px 0;
      }
      p {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 29px;
      }
      .experience4 {
        cursor: pointer;
        display: inline-block;
        width: 90px;
        height: 30px;
        background: #fff;
        border-radius: 15px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #4587ff;
        line-height: 30px;
        margin-top: 10px;
      }
    }
    .home-ban-content4 {
      margin-right: 154px;
      width: 181px;
      height: 360px;
      background-image: url("../assets/images/home/02.png");
      background-size: 100% 100%;
      text-align: center;
      img {
        width: 83px;
        height: 80px;
        margin-top: 44px;
      }
      h2 {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #4588ff;
        margin: 53px 0 15px 0;
      }
      p {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #4588ff;
        line-height: 29px;
      }
      .experience5 {
        cursor: pointer;
        display: inline-block;
        width: 90px;
        height: 30px;
        background: #4587ff;
        border-radius: 15px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 30px;
        margin-top: 10px;
      }
    }
  }
  .home-thied {
    background-color: #fbfcff;
    .el-carousel {
      .el-carousel__item {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        .item1 {
          justify-content: space-evenly;
          width: 100%;
          display: flex;
          align-items: center;

          .item_text_box {
            width: 540px;

            .title {
              font-size: 42px;
              font-family: PingFang SC;
              font-weight: bold;
              color: #333333;
            }
            .text {
              margin-top: 30px;
              font-size: 18px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 30px;
            }
          }

          .item_img_box {
            .img {
              width: 573px;
              height: 376px;
            }
            .img1 {
              width: 551px;
              height: 393px;
            }

            .img2 {
              width: 586px;
              height: 391px;
            }
            .img3 {
              width: 542px;
              height: 430px;
            }
            .img4 {
              width: 637px;
              height: 410px;
            }
          }
        }
      }
    }
    ::v-deep .el-carousel__indicators {
      .el-carousel__button {
        width: 11px;
        height: 11px;
        border-radius: 50%;
        margin-bottom: 20px;
        background-color: #7aa9ff;
      }
    }
  }
  .home-good {
    text-align: center;
    width: 100%;
    height: 697px;
    padding: 101px 390px 119px 389px;
    box-sizing: border-box;
    h2 {
      font-size: 46px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      margin-bottom: 26px;
    }
    h3 {
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #cccccc;
      margin-bottom: 76px;
    }
    .home-good-content {
      display: flex;
      // flex-wrap: wrap;
      justify-content: space-between;
      .home-box {
        display: flex;
        max-width: 494px;
        min-width: 439px;
        height: 132px;
      }
      img {
        width: 54px;
        height: 54px;
        margin-right: 25px;
      }
      h4 {
        font-size: 22px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #595a60;
        text-align: left;
        margin-top: 16px;
      }
      p {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #88898d;
        line-height: 30px;
        text-align: left;
        margin-top: 18px;
      }
    }
  }
  .banner-register {
    display: flex;
    width: 100%;
    height: 310px;
    background: url("../assets/images/01/04.png") no-repeat;
    background-size: 100% 100%;
    padding: 103px 0 104px 377px;
    box-sizing: border-box;
    img {
      width: 414px;
      height: 103px;
      margin-right: 200px;
    }
    .register-new {
      position: relative;
      width: 605px;
      height: 156px;
      background: url("../assets/images/01/08.png") no-repeat;
      background-size: 100% 100%;
      .el-input {
        position: absolute;
        top: 51px;
        left: 62px;
        width: 230px;
        border: none;
        ::v-deep .el-input__inner {
          background-color: #edf2f4 !important;
          border: none;
        }
      }
    }
  }
  .fooder-box1 {
    margin-bottom: -1px;
    width: 100%;
    height: 450px;
    padding: 0 197px;
    box-sizing: border-box;
    background-image: url("../assets/images/introduce/20.png");
    background-size: 100% 100%;
    text-align: center;
    overflow: hidden;
    .header {
      font-size: 41px;
      font-family: Source Han Sans CN;
      font-weight: 800;
      color: #ffffff;
      margin-top: 67px;
    }
    .header-hd {
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      opacity: 0.1;
      margin-top: 15px;
    }
    .timemarker {
      display: flex;
      justify-content: center;
      margin-top: 97px;
      font-size: 18px;
      color: #ffffff;
      .box {
        position: relative;
        text-align: center;
        width: 382px;
        height: 100%;
        margin-right: 200px;
        .box2 {
          position: absolute;
          top: -30px;
          left: 0;
          margin-left: 91px;
          width: 200px;
          height: 150px;
          background-image: url("../assets/images/introduce/21.png");
          background-size: 100% 100%;
        }
        .box3 {
          position: absolute;
          top: -45px;
          left: 0;
          margin-left: 106px;
          width: 150px;
          height: 180px;
          background-image: url("../assets/images/introduce/22.png");
          background-size: 100% 100%;
        }
      }
      .box1 {
        position: relative;
        text-align: center;
        width: 382px;
        height: 100%;
        .box4 {
          position: absolute;
          top: -25px;
          left: 0;
          margin-left: 86px;
          width: 190px;
          height: 140px;
          background-image: url("../assets/images/introduce/23.png");
          background-size: 100% 100%;
        }
      }
      .nub {
        font-size: 45px;
        font-family: Source Han Sans CN;

        margin-bottom: 29px;
        span {
          font-size: 30px;
          font-weight: 400;
        }
      }
    }
  }
  .bannerC {
    width: 100%;
    height: 432px;
    // color: #fff;
    color: #000;
    margin-bottom: 42px;
    background-image: url("../assets/images/introduce/03.png");
    background-size: 100% 100%;
    // background: url("../assets/images/01/03.png") no-repeat;
    // background-size: 100% 100%;
    padding: 50px 347px 0 348px;
    box-sizing: border-box;
    .fooder-top {
      display: flex;
      height: 100%;
      height: 290px;
      border-bottom: 1px solid #edeff5;
      // border-bottom: 1px solid #242424;
      .fooder-left {
        flex: 1;
        .fooder-hd {
          margin-left: -8px;
          img {
            width: 206px;
            height: 68px;
            margin-bottom: 49px;
          }
        }
        .fooder-bd {
          display: flex;
          font-size: 16px;
          .fooder-conter {
            div {
              cursor: pointer;
              margin: 0 254px 39px 0;
            }
          }
          .fooder-conter1 {
            div {
              cursor: pointer;
              margin-bottom: 39px;
            }
          }
        }
      }
      .fooder-right {
        flex: 1;
        text-align: right;
        font-size: 26px;
        font-family: PingFang SC;
        font-weight: 800;
        // color: #fff;
        color: #000;
        .right-box {
          margin: 21px 0 55px;
        }
        .img_box {
          display: flex;
          flex-direction: row-reverse;
          .imgcode {
            margin-left: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            font-size: 14px;
            font-weight: 500;

            img {
              margin-bottom: 10px;
              width: 90px;
              height: 90px;
            }

            .img {
              width: 96px;
              height: 96px;
            }
          }
        }
      }
    }
    .fooder-bottom {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 500;
      // color: #fff;
      color: #000;
      line-height: 30px;
      text-align: right;
    }
  }
}
::v-deep .wm-antiplagiarism-header {
  width: 0;
  height: 0;
  overflow: hidden;
  min-width: auto;
}
</style>
